import React, { useEffect } from 'react'
import * as S from './styles'
import { IconButton } from './IconButton'
import { Title } from './Title'
import { useDiagnosticStore } from '../../../store/Diagnostic'
import { useCoreContext } from '../../../hooks/contexts/coreContext/coreProvider'
import { CreateDiagnostic } from './Create'
import { useGetDiagnosticsByFamilyId } from '../../../queries/diagnostics/queries'

export const EmptyContent = () => <S.EmptyContainer>Sem Resultados</S.EmptyContainer>

export const ButtonShowDiagnostic = () => {
  const { userType } = useCoreContext()
  const { modalDiagnostics } = useDiagnosticStore()

  if (userType?.isPlanner || userType?.isSupport)
    return <S.ButtonCreate text="Diagnóstico Financeiro" onClick={() => modalDiagnostics(true)} />
}

export const ButtonCreateDiagnostic = () => {
  const { userType } = useCoreContext()
  const { modalCreateDiagnostic } = useDiagnosticStore()

  if (userType?.isPlanner || userType?.isSupport)
    return <S.ButtonCreate text="Criar Diagnóstico" onClick={() => modalCreateDiagnostic(true)} />
}

export const Diagnostic = () => {
  const { familiaId: familyId } = useCoreContext()
  const {
    showDiagnostics,
    showCreateDiagnostic,
    modalDiagnostics,
    refetchDiagnostics,
    modalDiagnosticsRefecth
  } = useDiagnosticStore()
  const { getDiagnosticsByFamilyIdData, getDiagnosticsByFamilyIdAsync } = useGetDiagnosticsByFamilyId()

  useEffect(() => {
    if (showDiagnostics && familyId) {
      getDiagnosticsByFamilyIdAsync({ familyId })
    }
  }, [showDiagnostics, familyId, getDiagnosticsByFamilyIdAsync])

  useEffect(() => {
    if (refetchDiagnostics && familyId) {
      getDiagnosticsByFamilyIdAsync({ familyId })
      modalDiagnosticsRefecth(false)
    }
  })

  if (!showDiagnostics && !showCreateDiagnostic) return <></>

  return (
    <>
      <S.ModalV2
        title={<Title />}
        className="modal-diagnostico"
        open={showDiagnostics}
        close={() => modalDiagnostics(false)}
      >
        {!getDiagnosticsByFamilyIdData?.length ? (
          <EmptyContent />
        ) : (
          <S.Content>
            <S.List>
              {getDiagnosticsByFamilyIdData?.map(({ pdfUrl, name, financialDiagnosticId: id }) => (
                <S.ListItem key={id}>
                  <S.ListItemDate>{name}</S.ListItemDate>
                  <S.ListItemButtons>
                    <IconButton type="trash" onClick={() => null} />
                    {pdfUrl && <IconButton type="download" onClick={() => window.open(pdfUrl)} />}
                    <IconButton type="share" onClick={() => null} />
                  </S.ListItemButtons>
                </S.ListItem>
              ))}
            </S.List>
          </S.Content>
        )}
      </S.ModalV2>

      <CreateDiagnostic />
    </>
  )
}
