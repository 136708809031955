import styled from 'styled-components'
import { LabelCheckBox } from '../../../../../Inputs/Checkbox'

export const Checkbox = styled(LabelCheckBox)`
  font-size: 0.85rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  input {
    margin-top: 0.4rem;
  }
`
