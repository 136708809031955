import React from 'react'
import * as S from './styles'

export const ConfirmOperation = ({ updateState, value, defaultChecked }) => {
  const confirmOperationHandleChange = (value: boolean) => updateState(!value)

  const Label = (
    <p>
      Considerar dentro de
      <strong> Gestão de Orçamentos</strong>
    </p>
  )

  return (
    <S.Checkbox
      label={Label}
      defaultChecked={defaultChecked}
      onChange={confirmOperationHandleChange}
      checked={value}
    />
  )
}
